<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import loginRegModal from "../popUpModals/loginReg";
import termsAndCondition from "../popUpModals/termsAndCondition";
import footerLayout from "../../layouts/footerLayout";
import appConfig from "@/app.config";
import ClassOnScroll from 'vue-class-on-scroll'
import simplebar from "simplebar-vue";

import Drawer from "vue-simple-drawer"

import jwt_decode from "jwt-decode";




import PincodeInput from 'vue-pincode-input';

import VueCountdown from '@chenfengyuan/vue-countdown';

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Vue from 'vue';
// import registerCopyVue from '../account/register - Copy.vue';

export default {
  page: {
    title: "Cloud Kitch",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    loginRegModal,
    termsAndCondition,
    footerLayout,
	simplebar,
    PincodeInput,
    VueCountdown,
    VueGoogleAutocomplete,
    Drawer
  },
  directives: {
  'class-on-scroll': ClassOnScroll,
},
 data() {
        return {
            fullName:'',
            emailID:'',
            RestName:'',
            RestAddress:'',
            city:'',
            country:'',
            message:'',
            emPanelError:'',
		   openMenuDrawer: false,
            emailIDforReg:'',
            searching: 0,
            changedInputValue:'',
            isValNum:0,
            countryCode:'+91',
            email:'',
            contact:'',
            code:'',
             show: false,
             counting: false,
        variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        // headerBgVariant: 'dark',
        // headerTextVariant: 'light',
        // bodyBgVariant: 'light',
        // bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
            isHidden: true,
            authError: '',
            isAuthError: false,
            regName:'',
            regEmail:'',
            regCorporateCode:'',
            regerror:'',
            hiddenOTPReg: true,
            codeReg: '',
            corporatesBranches: undefined,
            selectedBranchID:'',
            copBranchError:'',
            regSendOTPData: 0,
            regVerifyOTPData: 0,
            sendOTPData: 0,
            verifyOTPData: 0,
            userName:'',
            localStorageUserData: '',
            params: {
                    client_id: "855252858658-sihf66i67ut7cdul3hfm132b95r8agct.apps.googleusercontent.com"
                },
                // only needed if you want to render the button with the google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                }
        }
     }, 
     mounted()
     {
        this.getUserData();
        
        this.autoRefreshToken();
     },
methods: {

    addEmpanel()
		{
			let getEmpanelDetails = JSON.stringify({ 
				fullName: this.fullName,
                emailID: this.emailID,
                RestName:this.RestName,
                RestAddress:this.RestAddress,
                city:this.city,
                country:this.country,
                message:this.message
				});
			this.axios.post('/insertEmpanel', getEmpanelDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
                location.reload();
            }
            else if(status == 200 && response.data.status == 204)
            {
                this.emPanelError = response.data.message;
            }
            }).catch((error) => {
			    console.log(error.response.data.message);
			});
		},

    autoRefreshToken()
		{
			if (localStorage.getItem('uTid') && localStorage.getItem('uTid') != null) 
			{
				var getExp = jwt_decode(localStorage.getItem('uTid'));
				var currentDate  = new Date();
				var tokenExpire = new Date(getExp.exp * 1000);
				var difference = tokenExpire.getTime() - currentDate.getTime(); // This will give difference in milliseconds;
				var resultInMinutes = Math.round(difference / 60000);
				if(resultInMinutes <= 15 && resultInMinutes >= 0)
				{
					this.refreshTokens();
				}
				else if (resultInMinutes < 0) {
                    this.refreshTokens();
					// localStorage.clear();
					// Vue.prototype.$userData = '';
					// Vue.prototype.$placesAddress = '';
					// // this.$router.push('/');
					// window.location.href = '/';
				}
			}
		},

		refreshTokens()
		{
			// alert();
			 this.axios.get('/autoRefreshToken')
                    .then((response)=>{
					// console.log(response);
					let status = response.status;
					if(status == 200 && response.data.status == 200 && response.data.refreshedToken && response.data.refreshedToken != '')
					{
						console.log(response.data.refreshedToken);
						var userData = {
                                "utid":response.data.refreshedToken,
                            }
                            localStorage.setItem('uTid',JSON.stringify(userData));
							location.reload();
					} // if token is expired so it can be refreshed
					}).catch((error) => {
						// console.log(error);
				});
		},

    toggleMenuDrawer() {
			this.openMenuDrawer = !this.openMenuDrawer
		},
        openLoginModal()
		{
			this.openMenuDrawer = !this.openMenuDrawer;
			this.$bvModal.show("modal-login");

		},
    getUserData()
    {
        this.axios.get('/getUserDeatils')
                    .then((response)=>{
					// console.log(response);
                    // if(response.data.token && response.data.token != '')
					// {
					// 	var userData = {
                    //             "utid":response.data.token,
                    //         }
                    //         localStorage.setItem('uTid',JSON.stringify(userData));
					// } // if token is expired so it can be refreshed
					let status = response.status;
					if(status == 200 && response.data.status == 200)
					{
						if (response.data.userData.userName) {
                            this.userName = response.data.userData.userName;
                        }
                        else
                        {
                            this.userName = '';
                        }
                        if(response.data.userData.isTncAgreed == 0)
                        {
                            this.$bvModal.show("modal-termsCondition");
                        }
					}
                    else
                    {
                        // localStorage.clear();
                    }
					// this.cartStatus = response.data.cartdetails;
				}).catch((error) => {
							// localStorage.clear();
						console.log(error.response.data.message);
				});
    },
    logout()
		{
            this.$confirm("Are you sure you want to logout?","Logout","")
				.then(() => {
                                localStorage.clear();
                                Vue.prototype.$userData = '';
                                Vue.prototype.$placesAddress = '';
                                // this.$router.push('/');
                                location.reload();
                });
		},


         goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },

  },
};
</script>

<template>
  <!-- <Layout> -->
    <div>
        <header class="header clearfix element_to_stick headerCk" v-class-on-scroll="{ class: 'sticky', offset: 200 }">
        <div class="container">
            <div class="row">
				<div class="col-md-4 col-lg-4 col-xl-4 noMobile">
            <div id="logo">
                <div class="contact" v-class-on-scroll="{ class: 'contactSticky', offset: 200 }">
                   <!-- <img src="img/logo.svg" width="162" height="35" alt="" class="logo_normal">
                    <img src="img/logo_sticky.svg" width="162" height="35" alt="" class="logo_sticky"> -->
                    <i class="icon_phone"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
                </div>
            </div>
            </div>
				<div class="col-md-4 col-lg-4 col-xl-4 align-center">
					<div id="mainLogo logo">
                        <a href="/" class="noMobile">
                            <img src="../../templateAssests/img/cklogo.png"  v-class-on-scroll="{ class: 'mainLogoHide', offset: 10 }" alt="" style="object-fit:contain;height:55px;">
                        </a>
						<a href="/">
							<img class="mainLogoImg mainLogoHide" v-class-on-scroll="{ class: 'mainLogoShow', offset: 200 }" src="../../templateAssests/img/cklogo.png" height="55" alt="">
						</a>
					</div>
				</div>
            <!-- /top_menu -->
            <a href="#0" class="login_reg align-left" style="top:33px;">
                <i class="icon_phone" style="color:#fff;"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
            </a>
            <!-- <a href="#0" class="open_close"> -->
                <!-- <i @click="toggleMenuDrawer" class="icon_menu"></i> -->
							<!-- <Drawer @close="toggleMenuDrawer" align="left" :maskClosable="true" :closeable="false">
                                             <div v-if="openMenuDrawer">
												 <section class="head_section">
													<div  v-if="userName != ''" class="row">
													 <a href="/profile/my-profile">
														<div class="align-left"> -->
															<!-- <figure> -->
																<!-- <img class="userPlaceImage" src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="50" width="50" alt=""> -->
																<!-- </figure> -->
														<!-- </div>
														<div class="align-left">
															<a href="/profile/my-profile" class="userName" style="margin:8px 0 0 0; color:#212121; font-size:14px;">{{userName}}</a>
														</div>
                                                     </a>
													</div>
													<div v-else class="row">
														<div class="align-left">
															<p class="loginReg" @click="openLoginModal">Login / Register</p>
														</div>
													</div>
												 </section>
												 <hr style="margin:0px;"> -->
												 <!-- headMobileMenu -->

												 <!-- <section class="navigation_section">
													<ul class="dropdown-ul navigation_ul align-left">
														<a href="/"><li><img src="../../templateAssests/img/ckgallery/CloudKitch_logo_solo.png" style="object-fit:contain;margin-right:10px;" width="25" height="25" alt="">Home</li></a>
														<a @click="redirectCart"><li><img src="../../templateAssests/img/ckgallery/Cart.png" width="25" height="25" style="object-fit:contain;margin-right:10px;" alt="">Cart</li></a>
														<a href="/profile/my-orders"><li><i class="icon_bag_alt" style="font-size:20px;margin-right:10px;"></i>My Orders</li></a>
														<a href="/profile/my-subscriptions"><li><i class="mdi mdi-calendar-clock" style="font-size:20px;margin-right:10px;"></i>My Subscriptions</li></a>
														<a href="/profile/my-address"><li><i class="far fa-address-book" style="font-size:20px;margin-right:10px;"></i>My Address</li></a>
														<a href="/profile/my-profile"><li><i class="mdi mdi-account" style="font-size:20px;margin-right:10px;"></i>Profile</li></a>
													</ul> 
												 </section>
												 <hr class="hrBorder"> -->
												 <!-- navigation_section -->

												 <!-- <section class="help_section">
													 <h3 class="helpHead align-left">Help & Support</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<li><a href="/policies/terms-and-condition"><i class="mdi mdi-file-document-multiple-outline" style="font-size:20px;margin-right:10px;"></i>Terms & Conditions</a></li>
														<li><a href="/policies/grievance-policy"><i class="mdi mdi-file-document-outline" style="font-size:20px;margin-right:10px;"></i>Grievance Policy</a></li>
														<li><a href="/policies/cookie-policy"><i class="mdi mdi-cookie-outline" style="font-size:20px;margin-right:10px;"></i>Cookie Policy</a></li>
														<li><a href="/policies/cancellation-refund"><i class="mdi mdi-cash-refund" style="font-size:20px;margin-right:10px;"></i>Cancellation & Refund</a></li>
														<li><a href="#"><i class="icon_mail_alt" style="font-size:20px;margin-right:10px;"></i>Contact Us</a></li>
													</ul> 
												 </section>
												 <hr class="hrBorder"> -->
												 <!-- help_section -->

												 <!-- <section class="other_section" v-if="userName != ''">
													 <h3 class="helpHead align-left">Other</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<li><a @click="logout"><i class="uil uil-sign-out-alt" style="font-size:20px;margin-right:10px;"></i>Logout</a></li>
													</ul> 
												 </section> -->

												 <!-- <hr class="hrBorder"> -->
												 <!-- navigation_section -->
                                                 
                                             <!-- </div>
                                        </Drawer> -->
            <!-- </a> -->
            <a class="login_reg align-right" v-class-on-scroll="{ class: 'hideLogin_reg', offset: 200 }">
                <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu"
					 v-if="userName != ''"
					>
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
                <nav v-else>
                    <a href="/" class="show-submenu loginMob">HOME</a>
                    <a href="#0" class="show-submenu loginMob">CONTACT US</a>
                    <!-- <a class="show-submenu regMob" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a> -->
                </nav>
            </a>
<!-- /Mobile view -->

            <div class="col-md-4 col-lg-4 col-xl-4 align-right noMobile" style="margin-top:10px;">
            <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu"
					 v-if="userName != ''"
					>
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
					<nav class="main-menu" v-else>
					<ul>
                    <li class="submenu">
                        <a href="/" class="show-submenu">HOME</a>
                        <!-- <a href="/login" class="show-submenu">Login</a> -->
                    </li>
                    <li class="submenu">
                        <a href="#0" class="show-submenu">CONTACT US</a>
                        <!-- <a class="show-submenu" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a> -->
                        <!-- <a href="/register" class="show-submenu"><button class="btn_1 gradient" type="submit">Register</button></a> -->
                    </li>
                </ul>
					</nav>
            </div>
            </div>
        </div>
    </header>
    <!-- /header -->

    
    <main>
        <div class="header-video">
            <div id="hero_video">
                <!-- <div class="shape_element one"></div>
                <div class="shape_element two"></div> -->
                <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container displayText">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-7 col-lg-8 col-md-8 col-sm-4">
                              <!-- <img src="../../templateAssests/img/cklogo.png" alt="" class="ckLogo lazy"> -->
                                <!-- <h1 class="bannerTitle " style="font-weight:600;font-size:30px;">Value created for your business</h1>
                                <p class="bannerDesc" style="">Grow faster your business by leveraging our expertise and shared resources. Empanel with CloudKitch.</p> -->
                                <p class="bannerTitle" style="font-weight:600;">Grow faster your business by leveraging our expertise and shared resources.</p>
                                <p class="bannerDesc" style="font-weight:400;">Inviting Restaurants, Cloud Kitchens, Central Kitchens, Food Service Companies to join our platform.</p>
		                        <!-- <button type="submit" class="btn_1 mb_5" style="padding:14px 24px;font-size:16px;">Register</button> -->
                                <p class="text-center mt-3 d-block" style=""><a  @click="goto('div1reg')" class="btn_1 medium gradient pulse_bt mt-2">Empanel Now</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img id="teaser-video" class="teaser-video webVideo" src="../../templateAssests/img/ckgallery/banner02.jpg">
            <!-- <img src="../../templateAssests/img/video_fix.png" class="header-video--media" data-video-src="video/intro" data-teaser-source="video/intro" data-provider="" data-video-width="1920" data-video-height="960"> -->
            <!-- <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video webVideo"><source src="../../templateAssests/video/intro_web_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video>
            <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video mobVideo"><source src="../../templateAssests/video/intro_web_mobile_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video> -->
            <!-- <div class="wave hero"></div> -->
        </div>
        <!-- /header-video -->
        
        <!-- <div class="container main-cards">
            <div class="row cardsRow">
                <div class="col-4 col-md-3 col-lg-3">
                    <div class="item_version_2">
                        <a href="#"> -->
                        
                        <!-- <a href="/cafeterias"> -->

                            <!-- <figure class="cards">
                                <img src="../../templateAssests/img/nearby-rest.jpg" data-src="img/home_cat_pizza.jpg" alt="" class="">
                                <div class="info">
                                    <h3>Near by Restuarant</h3> -->

                                    <!-- <small>Avg price $40</small>  -->

                                <!-- </div>
                            </figure>
                        </a>
                    </div>
                </div>  
                <div class="col-4 col-md-3 col-lg-3">
                    <div class="item_version_2">
                        <a href="#">
                             -->
                        <!-- <a href="/cafeterias"> -->

                            <!-- <figure class="cards">
                                <img src="../../templateAssests/img/party-menu.jpg" data-src="img/home_cat_pizza.jpg" alt="" class="">
                                <div class="info">
                                    <h3>Party Menu</h3> -->

                                    <!-- <small>Avg price $40</small> -->

                                <!-- </div>
                            </figure>
                        </a>
                    </div>
                </div>
                <div class="col-4 col-md-3 col-lg-3">
                    <div class="item_version_2">
                        <a href="#">
                             -->
                        <!-- <a href="/restaurants/team_meeting"> -->

                            <!-- <figure class="cards">
                                <img src="../../templateAssests/img/corporate-events.jpg" data-src="img/home_cat_pizza.jpg" alt="" class="">
                                <div class="info">
                                    <h3>Corporate & Enterprises</h3> -->
                                    
                                    <!-- <small>Avg price $40</small> -->

                                <!-- </div>
                            </figure>
                        </a>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="shape_element_2 bg_white"  style="padding:50px 0 0px 0;">
            <div class="container" >
            <div class="main_title center">
                <span><em></em></span>
                <h2 style="margin-bottom:10px;color:#242424;font-family:Poppins;" class="">Why Empanel with CloudKitch?</h2>
                <p style="font-size:20px;/*color:#979797;*/ color:#626262;font-family:Poppins;font-weight:400;text-align:center;">
                    Our vision is to build a unified ecosystem aggregating demand from corporates to deliver services from our empanelled service providers.  
                    We believe that sharing ecosystems build magnificent scale, creating value for all stakeholders in the food value chain. We welcome you to empanel with CloudKitch and benefit from 
                    our ecosystem. 
                </p>
            </div>
                <div class="row " style="padding:50px 0 40px 0;" >
                    <div class="col-lg-6 colWidth" style="margin-right:auto;">
                        <h2 class="" style="color: #A4827D;font-size: 24px;margin-bottom:20px;font-family:Poppins !important;">How can CloudKitch help you?</h2>
                        <div class="row">
                            <div class="col-2">
                                <img src="../../templateAssests/img/ckgallery/revenue.png" alt="" height="40" width="50">
                            </div>
                            <div class="col-10">
                                <h5 class="foSection1_li_title" style="font-family:Poppins !important;">Revenue</h5>
                                <p class="foSection1_li_desc">
                                    We give you access to high volumes and diverse customer base. 
                                    The additional revenues flowing from CloudKitch App & business 
                                    intelligence tools help you build revenues.  
                                </p>
                            </div>
                            <!-- revenue end -->
                            <div class="col-2">
                                <img src="../../templateAssests/img/ckgallery/marketing.png" alt="" height="40" width="50">
                            </div>
                            <div class="col-10">
                                <h5 class="foSection1_li_title" style="font-family:Poppins !important;">Marketing</h5>
                                <p class="foSection1_li_desc">
                                    Display a sleek menu & display creatively, promote your 
                                    products, offer coupons & discounts run loyalty programs, 
                                    develop your brand identity and gain customer insights  
                                </p>
                            </div>
                            <!-- marketing end -->
                            <div class="col-2">
                                <img src="../../templateAssests/img/ckgallery/customers.png" alt="" height="40" width="50">
                            </div>
                            <div class="col-10">
                                <h5 class="foSection1_li_title">Customers</h5>
                                <p class="foSection1_li_desc">
                                    Get access to corporate offices, co-working, co-living and many 
                                    more enterprises in your city and sell to customers that were not 
                                    your targets.   
                                </p>
                            </div>
                            <!-- customers end -->
                            <div class="col-2 ">
                                <img src="../../templateAssests/img/ckgallery/simplicity.png" alt="" height="40" width="50" >
                            </div>
                            <div class="col-10">
                                <h5 class="foSection1_li_title">Simplicity</h5>
                                <p class="foSection1_li_desc">
                                    CloudKitch simplifies your business processes, enables your 
                                    growth & assists in building your capability.   
                                </p>
                            </div>
                            <!-- simplicity end -->
                            <!-- customers end -->
                            <div class="col-2">
                                <img src="../../templateAssests/img/ckgallery/innovation.png" alt="" height="40" width="50">
                            </div>
                            <div class="col-10">
                                <h5 class="foSection1_li_title">Innovation</h5>
                                <p class="foSection1_li_desc">
                                    Our tools prepare you to deliver a consistent experience every 
                                    time, innovate menus, products, promotions & gain more satisfied customers. 
                                </p>
                            </div>
                            <!-- innovation end -->
                        </div>
                    </div>
                    <div class="col-lg-5 colWidth">
                        <img class="" src="../../templateAssests/img/ckgallery/47.jpg" height="630" style="object-fit:cover;border-radius:10px;width:100%;" alt="">
                        <!-- <img class="" src="../../templateAssests/img/ckgallery/47.jpg" height="700" style="width:80%;margin-left:17%;" alt="">  -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

        <div class="shape_element_2 bg_white" style="padding:0px 0 0px 0;" >
            <div class="container" >
                <div class="row"  style="padding:0px 0 70px 0;">
                    <div class="col-lg-4 colWidth">
                        <img src="../../templateAssests/img/ckgallery/51.jpg"  height="275" style="width:100%;object-fit:cover;border-radius:10px;" alt=""> 
                    
                        <!-- <img src="../../templateAssests/img/ckgallery/51.jpg"  height="400" style="width:100%;object-fit:contain;" alt="">  -->
                    </div>
                    <div class="col-lg-5 colWidth" style="margin-left:auto;">
                        <h2 style="color: #A4827D;font-size: 22px;margin-bottom:10px;font-family:'Poppins';" class="">Digitise your food services, get future ready.</h2>
                        <div>
                             <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp;Power your food services with digital menus, accept digital payments, manage subsidy or meal plans & run promotions. 
                                    </p>
                                    <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp;Simplify operations and client interface by using our dashboards for your managers & your client stakeholders.
                                    </p>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp;Manage operating checklists, food safety & hygiene processes, employee records & compliances.
                                    </p>
                           <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp; Enhance your food service offering, integrate & aggregate multiple service providers.
                                    </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->
        <div class="shape_element_2 bg_white" style="padding:0px 0 0px 0;" >
            <div class="container" >
                <div class="row" style="padding:0px 0 70px 0;">
                    <div class="col-lg-5 colWidth" style=";margin-right:auto;">
                    <!-- <div class="offset-lg-1 align-self-center" style="text-align:start;"> -->
                        <h2 style="color: #A4827D;font-size:22px;margin-bottom:10px;font-family:Poppins;" class="">Food Service Operator Tools</h2>
                        <p class="foSection1_li_desc">Empanel with CloudKitch & work with our corporate clients. We help you to utilise spare capacity of your kitchens. </p>
                        <p class="foSection1_li_desc">For your own catering contracts, leverage our white labelled app-based solution, customize it for your own contracts.</p>
                    <!-- </div> -->
                    </div>
                    <div class="col-lg-5 colWidth" >
                        
                        <div class="row text-center" >
                            <div class="col-4">
                                
                                <img src="../../templateAssests/img/ckgallery/pos.png" alt="" style="object-fit:contain;" height="40" width="40">
                                <h5 class=" foSection1_li_desc py-4" style="font-family:Poppins !important; color:#F3766A;">POS</h5>
                                
                                
                            </div>
                            <div class="col-4">
                                
                                <img src="../../templateAssests/img/ckgallery/crm.png" alt="" style="object-fit:contain;" height="40" width="40">
                                <h5 class=" foSection1_li_desc py-4" style="font-family:Poppins !important;color:#F3766A;">CRM</h5>
                              
                            </div>  
                            <div class="col-4">
                                  
                                <img src="../../templateAssests/img/ckgallery/dashboard.png" alt="" style="object-fit:contain;" height="40" width="40">
                                <h5 class=" foSection1_li_desc py-4" style="font-family:Poppins !important; color:#F3766A;">Reports</h5>
                                
                            </div>  
                        </div>
                        <div class="row text-center">
                            <div class="col-4">
                                <div class="">
                                <img src="../../templateAssests/img/ckgallery/compliances.png" alt="" style="object-fit:contain;" height="40" width="40">
                                <h5 class=" foSection1_li_desc py-4" style="font-family:Poppins !important;color:#F3766A;">Compliances</h5>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="">
                                <img src="../../templateAssests/img/ckgallery/benchmark.png" alt="" style="object-fit:contain;" height="40" width="40">
                                <h5 class=" foSection1_li_desc py-4" style="font-family:Poppins !important;color:#F3766A;">Benchmarking</h5>
                                </div>
                            </div>  
                            <div class="col-4">
                               <div class="">
                                <img src="../../templateAssests/img/ckgallery/data-analysis.png" alt="" style="object-fit:contain;" height="40" width="40">
                                <h5 class=" foSection1_li_desc py-4" style="font-family:Poppins !important;color:#F3766A;">Data Analysis</h5>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape_element_2 bg_white" style="padding:0px 0 0px 0;" >
            <div class="container" >
                <div class="row"  style="padding:0px 0 70px 0;">
                    <div class="col-lg-4 colWidth">
                        <img src="../../templateAssests/img/ckgallery/banner05.jpg" height="200" style="width:100%;object-fit:cover;border-radius:10px;" alt=""> 
                    </div>
                    <div class="col-lg-5 colWidth" style="margin-left:auto;">
                        <h2 style="color: #A4827D;font-size: 22px;margin-bottom:10px;font-family:'Poppins';" class="">Patnership Opportunities</h2>
                        <p class="foSection1_li_desc">Empanel with CloudKitch & work with our corporate clients. We help you to utilise spare capacity of your kitchens. </p>
                        <p class="foSection1_li_desc">For your own catering contracts, leverage our white labelled app-based solution, customize it for your own contracts.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

        <div class="shape_element_2 bg_white">
            <div class="container" >
                <div class="row"  style="padding:0px 0 20px 0;">
                    <div class="col-lg-5 colWidth" style="margin-right:auto;">
                        <h2 style="color: #A4827D;font-size: 22px;margin-bottom:10px 0px 0px 100px;font-family:Poppins !important;" class="">Benefits of partnering with CloudKitch </h2>
                         <div>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp;Enhanced margins from your existing contracts.
                            </p>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp; Build your digital capability.
                            </p>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp; Improve productivity & efficiency of your employees.
                            </p>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp; Measure and manage performance of your business.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 colWidth">
                        <img src="../../templateAssests/img/ckgallery/7.jpg" height="300" style="height:65%;border-radius:10px;width:100%;object-fit:cover;" alt=""> 
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->
        <!-- <div class="shape_element_2 bg_white">
            <div class="container" >
                <div class="row" style="padding:50px 0 40px 0;">
                    <div class="col-lg-6 align-center">
                        <img class="removpadd" src="../../templateAssests/img/ckgallery/7.jpg" height="200" style="width:100%;object-fit:contain;padding-right:20px;" alt=""> 
                    </div>
                    <div class="col-lg-5 offset-lg-1 align-self-center newmobilepadd">
                        <h2 style="color: #A4827D;font-size: 22px;margin-bottom:10px;" class="">Benefits of partnering with CloudKitch </h2>
                        <div>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp;Enhanced margins from your existing contracts.
                            </p>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp; Build your digital capability.
                            </p>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp; Improve productivity & efficiency of your employees.
                            </p>
                            <p class="foSection1_li_desc foSection1_li_desc1">
                                    &bull;	&nbsp;	&nbsp; Measure and manage performance of your business.
                            </p>
                        </div>
                        <a href="emPanelForm" id="emPanelForm"></a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- /shape_element_2 -->
         
        <div class="shape_element_2" ref="div1reg">
           
            <div class="container emPanelSection" >
                <div class="align-center">
                    <h2 class="">Empanel with CloudKitch </h2>
                </div>
                <div  class="emPanelForm">
                    <form>
                    <b-form-group
                      label="Personal data"
                      label-for="formrow-firstname-input"
                      class="mb-3 "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-firstname-input"
                        placeholder="Name and Last name"
                         style="font-family:Poppins !important;"
                        v-model="fullName"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Contact Details"
                      label-for="formrow-firstname-input"
                      class="mb-3 Poppins "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-firstname-input"
                        placeholder="Phone No."
                         style="font-family:Poppins !important;"
                        v-model="phone"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      class="mb-3"
                    >
                      <b-form-input
                        type="text"
                        id="formrow-email-input"
                        placeholder="Email Address"
                        style="font-family:Poppins !important;"
                        v-model="emailID"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Restaurant data"
                      label-for="formrow-restaurant-input"
                      class="mb-3 "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-restaurant-input"
                        placeholder="Restaurant Name"
                         style="font-family:Poppins !important;"
                        v-model="RestName"
                      ></b-form-input>
                    </b-form-group>

                     <b-form-group
                      class="mb-3"
                    >
                      <b-form-input
                        type="text"
                        id="formrow-address-input"
                        placeholder="Address"
                          style="font-family:Poppins !important;"
                        v-model="RestAddress"
                      ></b-form-input>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          class="mb-3"
                        >
                          <b-form-input
                            id="formrow-city-input"
                            type="text"
                            placeholder="City"
                            v-model="city"
                             style="font-family:Poppins !important;"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                            
                        <select v-model="country" class="form-select">
                        <option value="">Country</option>
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      </div>
                    </div>
                    <b-form-group
                      label="Message"
                      label-for="formrow-message-input"
                      class="mb-3 "
                    >
                      <b-form-input
                        type="text"
                        id="formrow-message-input"
                        placeholder="Message.."
                         style="font-family:Poppins !important;"
                         v-model="message"
                      ></b-form-input>
                    </b-form-group>
                    

                    <!-- <div class="form-group">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="formrow-customCheck"
                        />
                        <label
                          class="form-check-label"
                          for="formrow-customCheck"
                          >Check me out</label
                        >
                      </div>
                    </div> -->
                    <!-- <div class="mt-4">
                      <b-button type="submit" variant="primary"
                        >Submit</b-button
                      >
                    </div> -->
                    <p v-if="emPanelError != ''" style="color:red;">{{emPanelError}}</p>
                    <p @click="addEmpanel" class="text-center mt-3 d-block"><a href="#0" class="btn_1 medium mt-2">Submit</a></p>
                  </form>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

    </main>
  <!-- /main -->


  

<loginRegModal />
<termsAndCondition />
<footerLayout style="display:block!important;"/>
    </div>
    <!-- /Root Div  -->
    
</template>

<!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/home.css"></style>
<style scoped>
.colWidth
{
    width:47%;   
}
main {
        font-family:'Poppins'!important;
  }
.bannerTitle
{
    /* font-weight:600; */
    font-size: 51px;
    margin-bottom: 10px!important;
    font-family:'Poppins'!important;
}
.bannerDesc
{
    /* font-weight:400; */
    font-size: 22px!important;
     font-family:'Poppins';
}
.foSection1_li_title
{
    margin: 8px 0px;
    color: #242424;
    /* font-size: 16px; */
    font-size: 18px;
    /* font-weight: 600; */
    font-family:'Poppins'!important;
}
.foSection1_li_desc
{
    /* color: #979797; */
    color: #626262;
    /* font-size: 14px; */
    font-size: 16px;
    font-family: 'Poppins';
}
.foSection1_li_desc1
{
    margin-bottom: 15px;
    font-family: 'Poppins';
}
.bg_white
{
    background: #ffffff;
}
.emPanelSection
{
    /* padding:50px; */
    padding-top:50px;
    padding-bottom:50px;
    width: 650px;
}
.header-video
{
    height:  425px !important;
}
.displayText
{
    padding-top: 120px;
}
.header-video img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: contain;
}
.headerCk
{
    position: fixed;
}
.contact{
        font-size:14px;
        color:#fff;
    }
    .contactAnch{
        color:#fff;
    }
    .contactSticky{
      color:#000!important;
      /* margin-top:15px; */
    }
    .contactSticky .contactAnch{
      color:#000;
    }
    .anchor:hover, .anchor:focus{
        color:#e54750;
    }
    .a.btn_1:hover, .btn_1:hover{
        color:#fff;
    }
    .userNameSticky{
        color:#000!important;
    }
    .main-cards{
        position:relative;
        top:-145px;
        z-index:9;
        /* z-index:100; */
    }
    .cards{
        max-width:222px;
        max-height:245.43px;
        border-radius:5px;
    }
    .cardsRow{
        justify-content:center;
    }
    .opacity-mask{
        background-color:rgba(0, 0, 0, 0.6);
    }
    .ckLogo {
          bottom: 43px;
           position: relative;
           object-fit: cover;
           height: 100px;
    }
    #logo{
        padding-top:14px;
    }
    .mainLogoImg{
        width: 100%;
        object-fit: contain;
    }
    .mainLogoShow{
        display: block!important;
        margin-top: 10px;
    }
    .mainLogoHide{
        display: none;
    }
    .modal-body{
        padding:30px!important;
    }
    .modalLoginText{
        font-size:24px;
        margin-bottom: 14px;
        color:#212121;
    }
    .emailInput{
        margin-bottom: px;
    }
    .otpBtn{
        height: 45px;
        background: #FFc60c;
        border: none;
        color: #212121;
        margin-bottom: 37px;
    }
    .divider{
        color:#eaeaea;
        margin-bottom: 29px;
    }
    .google img{
        margin-right: 28px;
    }
    .google{
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        border-radius: 5px;
        color:#212121;
        font-size: 14px;
    }
    a.social_bt.google:hover, a.social_bt.google:focus{
        border: 1px solid #777777;
    }
    .googleLoginBtn
    {
        width: 100%;
        background: transparent;
        border:none;
    }
    .signUptext{
        color: #212121;
        font-size: 14px;
    }
    .signUptext span a{
        color: #F3766A!important;
    }
    .modal-footer{
        height:50px!important;
    }
    .otpSection{
        margin-top: 40px;
    }
    .otpText{
        margin-bottom: 10px;
        color: #212121;
        font-size:14px;
    }
    .contactNumber{
        font-size:16px;
        color:#212121;
        margin-bottom: 17px;
    }
    .contactNumber a{
         color: #F3766A!important;
         font-size:13px;
    }
    .enterOTP{
        color:#777777!important;
        font-size:14px;
        margin-bottom:14px;
    }
    .otpInputs{
        margin-bottom: 16px;
    }
    .resendOTP{
        color: #777;
        margin-bottom: 15px;
         width:80%;
    }
    .resendOTP .timer{
        color: #F3766A;
       
    }
    .verifyotpBtn{
        width: 170px;
        height: 45px;
        background: #FFC60C;
        border-radius: 5px;
        border:none;
        margin-bottom: 19px;
    }
    #signUpConditions li{
        display:inline;
        margin-right: 10px;
        font-size: 10px;
        text-decoration: underline;
    }
    header{
        z-index: 101!important;
    }
    .closeModalDiv{
        float: right;
        padding-top: 6px;
        padding-right: 9px;
        cursor: pointer;
    }
    .inner-addon {
        position: relative;
    }
    .bxSearchModal{
        position: absolute;
        padding: 10px;
        pointer-events: none;
    }
    .errorDiv{
        font-size:14px;
        color: red;
    }
    .right-addon .bxSearchModal { right: 8px;font-size:22px;}
    .right-addon input { padding-right: 30px; border-radius: 20px;}
    /* @media screen and (max-width: 767px) {
           .conatiner{
             padding-left:0;
             padding-right:0;
           }
    } */
    </style>
    <style>
    .dropdown-toggle{
	margin-right: 10px!important;
    }
    .dropdown-toggle::after{
        display: none !important;
    }
    .dropdown-menu{
	    border-top: 3px solid #A4827D;
		width: 330px!important;
		max-width: 330px!important;
    }
    .user-menu{
        width: 144px!important;
        max-width: 144px!important;
        right: auto !important;
    }
    .dropdown-ul
    {
        list-style: none;
        padding: 0px!important;
    }
    .dropdown-ul li{
        padding: 12px 15px;
        color: #000;
    }
    .dropdown-ul li a{
        color: #000;
    }
    .dropdown-ul li a i{
        margin-right: 8px;
    }
    ul.dropdown-ul li a{
	    cursor: pointer;
    }
    .userPlaceImage
{
	object-fit: contain;
	border-radius: 25px;
}
.loginReg
{
	font-size: 18px;
	color: #F3766A;
}
.head_section
{
	margin-bottom: 20px;
}
/* .navigation_ul
{
	list-style-type: none;
	padding: 0;
} */
.navigation_ul li
{
	padding: 12px 0;
	font-size: 14px;
}
.navigation_ul li a
{
	color: #777;
}
.helpHead
{
	font-size: 16px;
	color: #777;
}
.login_reg
{
    display: none;
}
.login_reg a
{
    padding: 0 8px 10px;
}
.loginMob
{
    color: #fff;
}
.loginMob:hover, .loginMob:focus
{
    color: #e54750!important;
}
.hideLogin_reg
{
    display: none !important;;
}
.findFood
{
    max-width: 122px;
    position: absolute!important;
    top: 0px;
    right: 5px;
}
.ml-45
{
    margin-left: 45px;
}

@media only screen and (min-width: 320px) and (max-width: 769px){
    .ml-45
    {
        margin-left: 0px!important;
    }
    .colWidth
    {
        width: 100%!important;
    }
    
}


@media only screen and (max-width: 600px) {

    .newmobilepadd{
        padding-top: 20px;
    }

    .removpadd{
        padding-right: 0px;
    }
 
 
}


@media (max-width: 991px)
{
    .login_reg
    {
        display: block;
        position: absolute;
        left: 0px;
        top: 27px;
    }
    .findFood
    {
        margin: 0px 0 0 0!important;
        top: 5px;
        right: 15px;
    }
}
@media (max-width: 1024px)
{
    #hero_video {
        background: #ededed url(../../templateAssests/img/ckgallery/banner02.jpg) center center no-repeat;
        /* background: #ededed url(../img/ckgallery/black.jpg) center center no-repeat; */
        background-size: cover;
        background-position: center;
    }
}
    </style>
